@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.active {
  border-bottom: 3px solid #2748a5;
}

/*Start - Toggle Switch dark mode */
.label-dark {
  width: 50px;
  height: 26px;
  background-color: #111;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  transform: scale(1.5);
  @apply border border-gray-500;
}

.ball {
  width: 20px;
  height: 20px;
  @apply bg-gray-300 absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.check-dark:checked + .label-dark .ball {
  transform: translateX(24px);
}
/*End - Toggle Switch dark mode */

/*Start - Swiper Cards */
.swiper {
  @apply h-40 w-60 sm:h-72 sm:w-96 lg:h-80 lg:w-128 xl:w-144 text-xs sm:text-sm lg:text-2xl;
}

.swiper-slide {
  @apply flex items-center justify-center rounded-xl border border-gray-400 dark:border-gray-500;
}

.swiper-slide:nth-child(1n) {
  @apply bg-gray-50 dark:bg-gray-700;
}

.swiper-slide:nth-child(2n) {
  @apply bg-gray-50 dark:bg-gray-800;
}

.swiper-slide:nth-child(3n) {
  @apply bg-gray-50 dark:bg-gray-700;
}
/*End - Swiper Cards */

/* other swiper styles */
.swiper-2 {
  @apply h-32 w-full text-xs sm:text-sm lg:text-2xl;
}

.swiper-slide-2 {
  @apply flex items-center justify-center rounded-xl border-none;
}

.swiper-slide-2:nth-child(1n) {
  @apply bg-transparent dark:bg-white;
}
/*End - Swiper Cards */

/* Start - styles Navbar */
.nav-item,
.nav-item:after,
.nav-item:before {
  transition: all 0.5s;
}

.nav-item {
  @apply relative p-1 lg:p-2;
}
.nav-item:after {
  width: 0%;
  content: ".";
  color: transparent;
  height: 3px;
  @apply bg-blue-cide absolute bottom-0 left-0 right-0 m-auto;
}

.nav-item:hover:after {
  @apply w-full;
}

/* End - styles Navbar */


.accordion {
        @apply flex flex-col space-y-4;
    }

    .label {
        padding: 20px;
        @apply relative w-full bg-blue-cide text-white rounded-lg cursor-pointer hover:bg-gray-800 font-bold shadow-2xl;
    }

    .label::before {
        content: "+";
        position: absolute;
        right: 20px;
        top: 50%;
        @apply text-2xl lg:text-3xl font-bold;
        transform: translateY(-50%);
    }

    .content {
        @apply bg-gray-100 border-x-2    border-b-2 border-blue-cide rounded-b-md shadow-2xl;
        overflow: hidden;
        overflow-y: auto;
        height: 0;
        position: relative;
        transition: all 0.3s ease;
    }

    .content::-webkit-scrollbar {
        display: none;
    }

    .box.active .content {
        @apply p-3 lg:p-5 h-full;
    }

    .box.active .label::before {
        content: "-";
    }